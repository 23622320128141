import * as React from "react"

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector"
import { StepIconProps } from "@mui/material/StepIcon"
import Check from "@mui/icons-material/Check"
import { ArrowBack } from "@mui/icons-material"

import { styled } from "@mui/material/styles"
import { ApplicationTemplate, Application } from "src/types"
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  useMediaQuery,
} from "@mui/material"
import {
  PAGE_LABEL_BUSINESS_DETAILS,
  PAGE_LABEL_PROJECT_DETAILS,
} from "../intake_sections/constants"
import { isProjectApplication } from "../utils"
import { isPageRequired } from "../intake_sections/template_helpers"
import { customWordMapping } from "src/statics"

import MobileNavUpperBlock from "src/components/mobile-nav-upper-block/MobileNavUpperBlock"
import StagePieChart from "src/components/stage-pie-chart/StagePieChart"

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[500],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[500],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color:
        theme.palette.mode === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[500],
    }),
    "& .QontoStepIcon-completedIcon": {
      color:
        theme.palette.mode === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[500],
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }),
)

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  )
}

type Props = {
  activeStep: number
  handleBack: () => void
  steps: ApplicationTemplate["formTemplate"]["pages"]
  data: Application["data"]
  template?: ApplicationTemplate
}

export const COMPLETE = 99

export default function QontoStepper({
  steps,
  activeStep,
  data,
  handleBack,
  template,
}: Props) {
  const stepsLabels = steps.map((step) => step.label)
  const matchesXL = useMediaQuery("(max-width: 1199px)")
  const getLabel = React.useCallback(
    (index: number) => {
      if (
        isProjectApplication(data) &&
        stepsLabels[index] == PAGE_LABEL_BUSINESS_DETAILS
      ) {
        return customWordMapping(PAGE_LABEL_PROJECT_DETAILS, template)
      }
      return customWordMapping(stepsLabels[index], template)
    },
    [data, stepsLabels, template],
  )

  const isPageOptional = React.useCallback(
    (step: { label: string }) => {
      return (
        !isPageRequired(
          step.label,
          {
            formTemplate: { pages: steps },
          },
          data,
        ) && !step.label.includes("Additional")
      )
    },
    [steps, data],
  )

  const getMobileViews = (step: number) => {
    if (step === COMPLETE) {
      return <></>
    }
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
            {getLabel(activeStep)}{" "}
            {isPageOptional(steps[activeStep])
              ? `(${customWordMapping("Optional", template)})`
              : ""}
          </Typography>
          <MobileNavUpperBlock
            disable={false}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={stepsLabels}
          />
        </Box>
        <StagePieChart activeStep={activeStep} steps={stepsLabels} />
      </>
    )
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      {matchesXL ? (
        getMobileViews(activeStep)
      ) : (
        <>
          <Box>
            <Button
              id="back"
              onClick={handleBack}
              // sx={{ mt: 1, mr: 1 }}
              disabled={
                activeStep === 0 || activeStep === 99 || activeStep === -1
              }
              variant="contained"
              startIcon={<ArrowBack />}
            >
              Back
            </Button>
          </Box>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            connector={<QontoConnector />}
            style={{ width: "100%" }}
          >
            {steps.map((step, index) => {
              const labelProps: {
                optional?: React.ReactNode
              } = {}

              if (isPageOptional(step)) {
                labelProps.optional = (
                  <Typography variant="caption">
                    {customWordMapping("Optional", template)}
                  </Typography>
                )
              }
              return (
                <Step key={step.label}>
                  <StepLabel {...labelProps} StepIconComponent={QontoStepIcon}>
                    {index === activeStep ? (
                      <Typography variant="subtitle1">
                        {getLabel(index)}
                      </Typography>
                    ) : (
                      getLabel(index)
                    )}
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </>
      )}
    </Box>
  )
}
