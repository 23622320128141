import * as React from "react"
import { FormikProps } from "formik"

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material"
import * as Sentry from "@sentry/react"

import { LoadingButton } from "@mui/lab"

import { useAuth0 } from "@auth0/auth0-react"
import { useLocation, useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"

import { Application, CreditApplication } from "src/types"
import { confirm } from "src/components/confirm"

import { useApplicationTemplate } from "../queries/credit/useApplicationTemplate"
import { getUserEnabledSchema } from "../sections/@dashboard/intake_sections/schemas/UserRegistrationSectionSchema"

import {
  usePostGenerateBaseApplication,
  usePostGenerateBaseApplicationNoRedirect,
} from "../queries/credit/usePostGenerateBaseApplication"

import { AxiosError } from "axios"
import { usePostCompleteApplication } from "src/queries/credit/usePostCompleteApplication"
import { Helmet } from "react-helmet-async"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import queryString from "query-string"
import { usePostBuyerApplication } from "src/queries/credit/usePostBuyerApplication"
import { usePatchBuyerApplication } from "src/queries/credit/usePatchBuyerApplication"
import { usePostAnalytics } from "src/queries/analytics/usePostAnalytics"
import { useSnackbar } from "notistack"
import { useQueryClient } from "react-query"
import { error, info } from "src/utils/logger"
import ApplicationFormikForm, {
  validateErrorsAgainstSchema,
} from "src/sections/@dashboard/ApplicationFormikForm"
import BaseFormComponent from "src/sections/@dashboard/credit/forms/BaseFormComponent"
import auth0 from "auth0-js"
import { pdf } from "@react-pdf/renderer"
import PDFBaseApplicationOutput from "src/components/pdf/output-template/PDFBaseApplicationOutput"
import { getPdfTemplate } from "src/components/pdf/output-template/template"
import { LogoutOutlined } from "@mui/icons-material"
import SignerDeclarationDialog from "src/sections/@dashboard/credit/intake_sections/SignerDeclarationDialog"
import LoadingIcon from "src/assets/icons/loading-icon"
import { TypeAnimation } from "react-type-animation"

import { isMobile } from "react-device-detect"
import { hasCustomAddressQuestions } from "src/sections/@dashboard/utils"
import {
  PAGE_LABEL_ADDITIONAL_QUESTIONS,
  PAGE_LABEL_BANK_REFERENCE,
  PAGE_LABEL_BUSINESS_DETAILS,
  PAGE_LABEL_OWNERS,
  PAGE_LABEL_PERSONAL_GUARANTY,
  PAGE_LABEL_TRADE_REFERENCES,
  PAGE_LABEL_USER_DETAILS,
} from "src/sections/@dashboard/intake_sections/constants"
import { isPageEnabled } from "src/sections/@dashboard/intake_sections/template_helpers"

export default () => {
  const [activeStep, setActiveStep] = React.useState(0)
  // used after redirection from auth0
  // if the user already filled the first page and logged in, skip the first page
  const [autoNavigate, setAutoNavigate] = React.useState(true)
  const params = useParams()
  const { id } = params

  const navigate = useNavigate()
  const {
    user: auth0User,
    isAuthenticated,
    logout,
    loginWithRedirect,
  } = useAuth0()

  const { data: application, refetch: refetchApplication } =
    useCreditApplication(id)

  const [signerInvitationDialogOpen, setSignerInvitationDialogOpen] =
    React.useState(false)

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const consentResult = queryParams.get("result") || ""
  const businessId = queryParams.get("business_id") || application?.seller?.id

  // any query params will be passed to formik as initial values
  const prefilledData = queryString.parse(search)
  delete prefilledData["business_id"]
  delete prefilledData["request_type"]

  const { data: businessData } = useAnonymousBusiness(businessId || "")

  const { enqueueSnackbar } = useSnackbar()

  const queryClient = useQueryClient()

  // used to show a blocking error if completion fails
  const [completionError, setCompletionError] = React.useState<
    AxiosError | undefined
  >(undefined)

  const [loading, setLoading] = React.useState(false)
  const [verifyCode, setVerifyCode] = React.useState<string | undefined>(
    undefined,
  )

  const formRef = React.useRef<FormikProps<CreditApplication>>(null)

  const {
    data: template,
    dataSchema,
    steps,
    applicationError,
  } = useApplicationTemplate(
    businessId || undefined,
    true,
    true,
    id,
    formRef.current?.values.data,
  )
  const getLastSection = (formData: Application["data"]) => {
    let lastSection = ""
    if (template) {
      for (const fields of template?.customFields || []) {
        if (fields.associatedPage === PAGE_LABEL_ADDITIONAL_QUESTIONS) {
          return PAGE_LABEL_ADDITIONAL_QUESTIONS
        }
      }

      // label is listed in the order of the pages
      for (const page_label of [
        PAGE_LABEL_USER_DETAILS,
        PAGE_LABEL_BUSINESS_DETAILS,
        PAGE_LABEL_OWNERS,
        PAGE_LABEL_TRADE_REFERENCES,
        PAGE_LABEL_BANK_REFERENCE,
        PAGE_LABEL_PERSONAL_GUARANTY,
      ]) {
        const page = template.formTemplate.pages.find(
          (page) => page.label === page_label,
        )
        if (page && isPageEnabled(page.label, template, formData)) {
          lastSection = page.label
        }
      }
    }
    return lastSection
  }

  const { execute: generateApplication, isLoading: isLoadingRedirect } =
    usePostGenerateBaseApplication((data: CreditApplication) => {
      if (data.data["signingUrl"]) {
        saveAnalytics("submit", "credit", data.id || "", "Awaiting Signature")
        window.location.href = data.data["signingUrl"]
      } else {
        setActiveStep(99)
      }
    })

  const {
    execute: generateApplicationNoRedirect,
    isLoading: isLoadingNoRedirect,
  } = usePostGenerateBaseApplicationNoRedirect((data: CreditApplication) => {
    saveAnalytics("submit", "credit", data.id || "", "Awaiting Signature")
  })

  const isLoading = isLoadingRedirect || isLoadingNoRedirect

  const startPasswordlessLogin = React.useCallback(
    (values: CreditApplication) => {
      const data = { ...values.data }
      if (data) {
        delete data["customFields"]
        delete data["customResponsesToBeDeleted"]
        data["email"] = data["email"]?.toLowerCase()
        setLoading(true)
        let redirectUri = ""
        if (data["id"]) {
          redirectUri =
            window.location.origin +
            "/trade-credit/redirect?" +
            queryString.stringify({
              application_id: data["id"],
              business_id: data.seller,
              type: "trade-credit",
            })
        } else {
          redirectUri =
            window.location.origin +
            "/trade-credit/base?" +
            queryString.stringify({
              ...data,
              business_id: data.seller,
            })
        }
        new auth0.WebAuth({
          domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
          clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
          redirectUri,
          responseType: "token",
        }).passwordlessStart(
          {
            send: "code",
            email: data.email,
            connection: "email",
          },
          (err) => {
            setLoading(false)
            if (!err) {
              setVerifyCode("")
            } else {
              error(err)
            }
          },
        )
      }
    },
    [],
  )

  const { execute: saveApplicationProgress } = usePatchBuyerApplication(() => {
    queryClient.removeQueries()
    enqueueSnackbar("Application Saved.", {
      variant: "info",
      preventDuplicate: true,
    })
  })

  const { execute: saveAnalytics } = usePostAnalytics()

  const onIncrementStep = React.useCallback(
    (
      id: string,
      values: CreditApplication,
      onError = () => {
        console.log("no-op")
      },
    ) => {
      saveApplicationProgress(
        id,
        values,
        () => {
          saveAnalytics("save", "credit", id, steps[activeStep].label, {
            seller: values.seller?.name || "",
            buyer: `${values.data.firstName} ${values.data.lastName}`,
            buyerEmail: auth0User?.email || "",
            buyerPhoneNumber: values.data.userPhoneNumber,
          })
          refetchApplication()
            // eslint-disable-next-line promise/no-nesting
            .then(() => {
              return setActiveStep((prevActiveStep) => prevActiveStep + 1)
            })
            // eslint-disable-next-line promise/no-nesting
            .catch((err) => {
              error(err)
            })
        },
        onError,
      )
    },
    [
      activeStep,
      auth0User?.email,
      refetchApplication,
      saveAnalytics,
      saveApplicationProgress,
      steps,
    ],
  )

  const { execute: createApplication } = usePostBuyerApplication(
    (newApplication) => {
      if (isAuthenticated && newApplication.id && formRef.current) {
        // saving is required to upload the files in memory
        saveApplicationProgress(
          newApplication.id,
          formRef.current.values,
          () => {
            navigate(
              `/trade-credit/${newApplication.id}?` +
                queryString.stringify({
                  business_id: businessId,
                }),
            )
          },
        )
      } else if (!isAuthenticated && newApplication.id && formRef.current) {
        new auth0.WebAuth({
          domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
          clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
          redirectUri:
            window.location.origin +
            "/trade-credit/redirect?" +
            queryString.stringify({
              application_id: newApplication?.id || "",
              business_id: businessId,
              type: "trade-credit",
            }),
          responseType: "token",
        }).passwordlessLogin(
          {
            connection: "email",
            email: newApplication.data.email || "",
            verificationCode: verifyCode || "",
          },
          () => {
            setLoading(false)
            enqueueSnackbar("Vaidation failed. Please try again.", {
              variant: "error",
            })
            // TODO: delete application
          },
        )
      }
    },
  )

  const { execute: completeApplication, isLoading: isPatching } =
    usePostCompleteApplication()

  const initialValue = React.useMemo(() => {
    if (!id) {
      return {
        data: {
          seller: businessId,
          ...prefilledData,
        },
        stage: 1,
      } as CreditApplication
    }

    if (application) {
      return { ...application, ...prefilledData }
    }

    // will never happen because if ther is an id
    // we dont render the form until application is loaded
    return {
      data: {
        seller: businessId,
        customFields: {},
      },
      stage: 1,
    } as CreditApplication
  }, [id, application, businessId, prefilledData])

  const onSubmit = React.useCallback(() => {
    if (id && formRef.current) {
      const values = formRef?.current.values
      saveApplicationProgress(id, values, () => {
        values.data["id"] = values.id
        if (businessData && template) {
          saveAnalytics("save", "credit", id, steps[activeStep].label, {
            seller: values.seller?.name || "",
            buyer: `${values.data.firstName} ${values.data.lastName}`,
            buyerEmail: auth0User?.email || "",
            buyerPhoneNumber: values.data.userPhoneNumber,
          })
          refetchApplication()
            .then(() => {
              if (values === undefined) {
                throw new Error("Form values are undefined")
              }
              return generateApplicationNoRedirect(values)
            })
            .then(() => {
              return setSignerInvitationDialogOpen(true)
            })
            .catch((err) => {
              Sentry.captureException(err)
              console.log(err)
            })
        }
      })
    }
  }, [
    activeStep,
    auth0User?.email,
    businessData,
    generateApplicationNoRedirect,
    id,
    refetchApplication,
    saveAnalytics,
    saveApplicationProgress,
    steps,
    template,
  ])

  const onSubmitByAuthorizedSigner = React.useCallback(
    (first: string, last: string) => {
      if (id && formRef.current) {
        const values = formRef?.current.values
        saveApplicationProgress(id, values, () => {
          values.data["id"] = values.id
          if (businessData && template) {
            refetchApplication()
              .then((res) => {
                return pdf(
                  <PDFBaseApplicationOutput
                    data={res.data as CreditApplication}
                    businessData={businessData}
                    customQuestionsTemplate={template.customFields}
                    pdfTemplate={getPdfTemplate(template)}
                  />,
                ).toBlob()
              })
              .then((res) => {
                values["files"] = [new File([res], "application_pdf.pdf")]
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                values["signerFirst"] = first
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                values["signerLast"] = last

                return generateApplication(values)
              })
              .catch((err) => console.log(err))
          }
        })
      }
    },
    [
      businessData,
      generateApplication,
      id,
      refetchApplication,
      saveApplicationProgress,
      template,
    ],
  )

  React.useEffect(() => {
    if (id && ["complete", "success"].includes(consentResult)) {
      completeApplication(
        id,
        () => {
          saveAnalytics("complete", "credit", id, "Completed")
          setActiveStep(99)
        },
        (error: AxiosError) => {
          setCompletionError(error)
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentResult])

  const onNextStep = React.useCallback(
    (
      stepsRemaining?: boolean,
      onFailed = () => {
        console.log("no-op")
      },
    ) => {
      if (activeStep === 0) {
        if (
          isAuthenticated &&
          auth0User?.email !== formRef?.current?.values.data.email &&
          !application?.collaborators?.includes(auth0User?.email || "")
        ) {
          confirm(
            `You are currently logged in as ${auth0User?.email}. Would you like to log out and continue with ${formRef?.current?.values.data.email}?`,
          )
            // eslint-disable-next-line promise/no-nesting
            .then(
              () => {
                logout({
                  logoutParams: {
                    returnTo:
                      window.location.origin +
                      "/ua-trade-credit/base?" +
                      queryString.stringify({
                        ...formRef?.current?.values.data,
                        applicationId: id,
                        business_id: businessId,
                        // used to automatically
                        // move the user to 2FA verification. see related useEffect
                        verify: true,
                      }),
                  },
                })
                return
              },
              () => {
                onFailed()
                info("cancelled")
              },
            )
            // eslint-disable-next-line promise/no-nesting
            .catch((e: any) => {
              console.log("error while logout", e)
            })
        } else if (!isAuthenticated) {
          onFailed()
          startPasswordlessLogin(formRef?.current?.values as CreditApplication)
        } else {
          // user is logged in, redirect to the protected route
          if (id) {
            onIncrementStep(
              id,
              formRef?.current?.values as CreditApplication,
              onFailed,
            )
          } else {
            createApplication(
              formRef?.current?.values as CreditApplication,
              () => undefined,
              onFailed,
            )
          }
        }
      } else if (activeStep === steps.length - 1) {
        onSubmit()
      } else {
        if (id) {
          onIncrementStep(
            id,
            formRef?.current?.values as CreditApplication,
            onFailed,
          )
        }
      }
    },
    [
      activeStep,
      application?.collaborators,
      auth0User?.email,
      businessId,
      createApplication,
      id,
      isAuthenticated,
      logout,
      onIncrementStep,
      onSubmit,
      startPasswordlessLogin,
      steps.length,
    ],
  )

  React.useEffect(() => {
    if (
      autoNavigate &&
      template &&
      // make sure we had any saved data - if not, we don't need to do this
      (Object.keys(prefilledData).length > 0 || !!application) &&
      // make sure the template is fully loaded
      Object.keys(getUserEnabledSchema(template, formRef.current?.values.data))
        .length > 0 &&
      // for now we only do this on the first step
      activeStep === 0 &&
      formRef.current &&
      !consentResult
    ) {
      const userEnabledschema = getUserEnabledSchema(
        template,
        formRef.current?.values.data,
      )

      const handleNext = () => {
        setAutoNavigate(false)
        if (
          isAuthenticated &&
          (auth0User?.email === formRef.current?.values.data.email ||
            application?.collaborators?.includes(auth0User?.email || ""))
        ) {
          if (!id && !application && formRef.current) {
            if (queryParams.get("applicationId")) {
              navigate({
                pathname: `/trade-credit/${queryParams.get("applicationId")}`,
                search: queryString.stringify({
                  ...formRef.current.values.data,
                  business_id: businessId,
                }),
              })
            }
          } else if (id && formRef.current) {
            onIncrementStep(id, formRef?.current?.values)
          }
        } else {
          setAutoNavigate(false)
          startPasswordlessLogin(formRef?.current?.values as CreditApplication)
        }
      }
      validateErrorsAgainstSchema(
        formRef,
        () => {
          handleNext()
        },
        userEnabledschema,
        () => {
          setAutoNavigate(false)
          setActiveStep(0)
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefilledData, template, autoNavigate, activeStep])

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  if (!template || steps.length === 0) return <></>

  if (id && !application)
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {![401, 403].includes(
          (applicationError as AxiosError)?.response?.status || 0,
        ) && <Typography>Loading application...</Typography>}
        {[401, 403].includes(
          (applicationError as AxiosError)?.response?.status || 0,
        ) && (
          <>
            <Typography>
              The email you are logged in is not associated with this
              application. Please log out using the button below and make sure
              you log in using the email address associated that:
            </Typography>
            <ul>
              <li>you initially submitted this application with, or</li>
              <li>you received the communication at.</li>
            </ul>
            <Button
              variant="contained"
              startIcon={<LogoutOutlined />}
              style={{ marginTop: "1rem" }}
              onClick={() => logout({})}
            >
              Log out
            </Button>
          </>
        )}
      </Box>
    )

  return (
    <>
      <Helmet>
        <title>
          {businessData?.name
            ? `${businessData?.name} Credit Application`
            : "Credit Application"}
        </title>
      </Helmet>
      <Dialog open={isMobile && !isAuthenticated}>
        <DialogContent>
          <Typography>
            Please sign in to continue using our Credit Application on your
            mobile device.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              localStorage.setItem(
                // short for auth0 query params
                "a0qps",
                queryString.stringify({
                  application_id: "",
                  business_id: businessId,
                  type: "trade-credit",
                }),
              )

              loginWithRedirect({
                authorizationParams: {
                  redirect_uri:
                    window.location.origin + "/trade-credit/redirect",
                },
              })
            }}
          >
            Take me to sign in page
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || isPatching}
        maxWidth={false}
      >
        <DialogContent style={{ width: "30vw" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {/* <CircularProgress color="inherit" /> */}
            <LoadingIcon />
            <TypeAnimation
              sequence={[
                "Redirecting you to sign the credit agreement...",
                1000,
              ]}
              style={{ fontSize: "1em" }}
              speed={99}
              deletionSpeed={99}
              repeat={0}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <ApplicationFormikForm
        template={template}
        steps={steps}
        dataSchema={dataSchema}
        application={application as CreditApplication}
        activeStep={activeStep}
        initialValues={initialValue}
        handleNext={onNextStep}
        handleBack={handleBack}
        formRef={formRef}
        completionError={completionError}
        Component={BaseFormComponent}
      />
      <Dialog
        open={verifyCode !== undefined}
        onClose={() => {
          setLoading(false)
          setVerifyCode(undefined)
        }}
      >
        <DialogTitle>Verify your email</DialogTitle>
        <DialogContent>
          To ensure the privacy and security of your information, we have sent a
          verification code to your email. Please enter it below to continue.
          <TextField
            autoFocus
            margin="dense"
            id="code"
            label="Verification Code"
            type="number"
            value={verifyCode}
            helperText="Not finding your verification email? Please check your spam folder."
            onChange={(event) => {
              setVerifyCode(event.target.value)
            }}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setLoading(false)
              setVerifyCode(undefined)
            }}
          >
            Close
          </Button>
          <LoadingButton
            id="verify-button"
            loading={loading}
            disabled={!verifyCode}
            onClick={() => {
              if (formRef?.current?.values) {
                setLoading(true)
                createApplication({
                  data: formRef?.current?.values.data,
                  seller: businessId,
                })
              } else {
                console.log("formRef.current.values is undefined")
              }
            }}
          >
            Verify
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <SignerDeclarationDialog
        open={signerInvitationDialogOpen}
        overrideHeight={hasCustomAddressQuestions(
          template?.customFields,
          getLastSection(formRef?.current?.values.data as Application["data"]),
          formRef?.current?.values.data as Application["data"],
        )}
        onClose={() => setSignerInvitationDialogOpen(false)}
        onSubmit={onSubmitByAuthorizedSigner}
      />
    </>
  )
}
