import { useState, useEffect } from "react"
import { useUser } from "../../queries"
import { useAuth0 } from "@auth0/auth0-react"
import { Outlet, useNavigate, useLocation } from "react-router-dom"
// @mui
import { styled } from "@mui/material/styles"
//
import Header from "./header"
import Nav from "./nav"
import axios from "axios"
import { Buffer } from "buffer"
import { error } from "../../utils/logger"

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
})

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false)

  const { data, isLoading: isUserLoading, refetch } = useUser()

  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  const location = useLocation()

  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      if (process.env.REACT_APP_USE_AUTH0 === "false") {
        const audience = process.env.REACT_APP_AUTH0_AUDIENCE
        const client_id = process.env.REACT_APP_AUTH0_CLIENT_ID
        const scope = "openid email profile offline_access"
        const cookie = window.localStorage.getItem(
          `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::${process.env.REACT_APP_AUTH0_AUDIENCE}::openid email profile offline_access`,
        )
        if (
          !cookie ||
          (JSON.parse(cookie)?.expiresAt &&
            JSON.parse(cookie)?.expiresAt < Date.now() / 1000)
        ) {
          axios
            .request({
              method: "POST",
              url:
                "https://" +
                process.env.REACT_APP_AUTH0_DOMAIN +
                "/oauth/token",
              headers: { "Content-Type": "application/json" },
              data: {
                grant_type: "http://auth0.com/oauth/grant-type/password-realm",
                realm: "Username-Password-Authentication",
                username: "dev@netnow.io",
                password: process.env.REACT_APP_AUTH0_DEV_PASSWORD,
                email: "dev@netnow.io",
                audience,
                scope,
                client_id,
                client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
              },
            })
            .then((res) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const base64Url = res.data.id_token.split(".")[1] // token you get
              const base64 = base64Url.replace("-", "+").replace("_", "/")
              const claims = JSON.parse(
                Buffer.from(base64, "base64").toString("binary"),
              )
              // const claims = jwt.decode(body.id_token)
              const { nickname, name, picture, updated_at, sub, exp } = claims

              const {
                access_token,
                id_token,
                token_type,
                expires_in,
                refresh_token,
              } = res.data

              const item = {
                body: {
                  access_token,
                  audience,
                  client_id,
                  id_token,
                  oauthTokenScope: scope,
                  expires_in,
                  refresh_token,
                  scope,
                  token_type,
                  decodedToken: {
                    claims,
                    user: {
                      nickname,
                      name,
                      picture,
                      updated_at,
                      sub,
                    },
                  },
                },
                expiresAt: exp,
              }

              window.localStorage.setItem(
                `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::${process.env.REACT_APP_AUTH0_AUDIENCE}::openid email profile offline_access`,
                JSON.stringify(item),
              )

              return refetch()
            })
            .catch((err) => {
              error(err)
            })
        }
      } else {
        loginWithRedirect({
          appState: {
            redirectTo: location.pathname,
            params: location.search,
          },
        })
      }
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, location, refetch])

  // reroute logic based on user type
  useEffect(() => {
    if (data && data.groups) {
      const business = data.userToBusiness?.business
      if (!business) {
        //TODO: this doesn't have to be so strict. we can just redirect to /onboarding/user if the user drifts off
        // seller that has not onboarded. buyers should have a business by this point
        if (data.groups.includes(2)) {
          if (!location.pathname.includes("/onboarding/user")) {
            navigate("/onboarding/user")
          }
        } else if (!location.pathname.includes("/onboarding/business")) {
          navigate("/onboarding/business")
        }
      }

      // // for buyers
      // if (data.groups.includes(4)) {
      //   if (!location.pathname.includes("invoice")) navigate("/invoices")
      // } else if (
      //   data.userToBusiness?.external &&
      //   data.userToBusiness?.external.length > 0 &&
      //   !location.pathname.includes("prequalify")
      // ) {
      //   // marketplace users can only go to prequalify right now
      //   navigate("/prequalify")
      // }
    }
  }, [data, location, navigate])

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        {data && !isUserLoading && !isLoading && <Outlet />}
        {(!data || isUserLoading || isLoading) && <>Loading...</>}
      </Main>

      {/* <PlaidDialog
        open={
          data?.userToBusiness?.business?.needsPlaid === true &&
          !location.pathname.includes("/invoice") &&
          !location.pathname.includes("/prequalify")
        }
        businessId={data?.userToBusiness?.business?.id}
        onExit={() => {
          // using delay to avoid concurrency issues causing stale data to be returned
          setTimeout(() => {
            refetch()
          }, 5000)
        }}
      />

      <RailzAI
        open={
          data?.userToBusiness?.business?.needsPlaid === false &&
          data?.userToBusiness?.business?.needsRailz === true
        }
        onExit={() => {
          // using delay to avoid concurrency issues causing stale data to be returned
          setTimeout(() => {
            refetch()
          }, 5000)
        }}
      /> */}
    </StyledRoot>
  )
}
